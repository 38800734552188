<template>
    <div>
        <h1 class="text-center">
            تفاصيل الحجز رقم
            <span class="blue--text mr-3 font-weight-bold">
                {{ detail.reservationNo }}
            </span>
        </h1>

        <v-row no-gutters class="mt-4">
            <v-col cols="12" md="3" class="pl-2 text-center">
                <v-alert outlined style="height:160px">
                    <p>معلومات الحجز</p>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">وقت الحجز</span>
                        <span class="font-weight-bold">
                            {{ detail.checkinTime }}
                        </span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">تاريخ الحجز</span>
                        <span class="font-weight-bold">
                            {{ detail.checkinDate | formatDate }}
                        </span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">تاريخ مغادرة الحجز</span>
                        <span class="font-weight-bold">
                            {{
                                detail.checkoutDate == null
                                    ? "غير محدد"
                                    : detail.checkoutDate | formatDate
                            }}
                        </span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">وقت المغادرة</span>
                        <span class="font-weight-bold">
                            {{
                                detail.checkoutTime == null
                                    ? "غير محدد"
                                    : detail.checkoutTime
                            }}
                        </span>
                    </div>
                </v-alert>
            </v-col>

            <v-col cols="12" md="3" class="pl-2 text-center">
                <v-alert outlined style="height:160px">
                    <p>تفاصيل الحجز</p>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">حالة الحجز</span>
                        <span class="font-weight-bold">
                            {{ detail.status ? "منتةي" : "مستمر" }}
                        </span>
                    </div>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text "
                            >المستخدم الذي قام باضافة الحجز</span
                        >
                        <span class="font-weight-bold">
                            {{ detail.createdUserUserName }}
                        </span>
                    </div>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">تاريخ الانشاء</span>
                        <span class="font-weight-bold">
                            {{ detail.createdDate | formatDate }}
                        </span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">تاريخ اخر تعديل</span>
                        <span class="font-weight-bold">
                            {{ detail.modifiedDate | formatDate }}
                        </span>
                    </div>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 text-center">
                <v-alert outlined style="height:160px">
                    <p>تفاصيل الوفد</p>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text "> اسم الوفد</span>
                        <span class="font-weight-bold">
                            {{
                                detail.delegationName == null
                                    ? "لا يوجد نوع الحجز عادي"
                                    : detail.delegationName
                            }}
                        </span>
                    </div>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text "> معرف الوفد</span>
                        <span class="font-weight-bold">
                            {{
                                detail.delegationID == null
                                    ? "لا يوجد نوع الحجز عادي"
                                    : detail.delegationName
                            }}
                        </span>
                    </div>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text "> عدد النزلاء</span>
                        <span class="font-weight-bold">
                            {{ detail.delegationCount }}
                        </span>
                    </div>
                </v-alert>
            </v-col>

            <v-col cols="12" md="3" class="pl-2 text-center">
                <v-alert outlined v-if="detail.identifier" style="height:160px">
                    <p>تفاصيل المعرف</p>
                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">الاسم الكامل</span>
                        <span class="font-weight-bold">
                            {{ detail.identifier.identifierName }}
                        </span>
                    </div>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">معرف التليكرام</span>
                        <span class="font-weight-bold">
                            {{ detail.identifier.telegramId }}
                        </span>
                    </div>

                    <div class="d-flex justify-space-between">
                        <span class="grey--text ">رقم الهاتف</span>
                        <span class="font-weight-bold">
                            {{ detail.identifier.phoneNo }}
                        </span>
                    </div>
                </v-alert>
            </v-col>

            <v-col cols="12" md="6" class="pl-2 text-center">
                <v-alert outlined color="blue" border="left">
                    <p class="mb-3">تفاصيل النزلاء</p>
                    <span v-if="detail.guests == ''"> لا يوجد نزلاء </span>
                    <div v-for="(item, index) in detail.guests" :key="index">
                        <table class="" style="width:100%">
                            <tr class="grey--text">
                                <th>الاسم الكامل</th>
                                <th>نوع الوثيقة</th>
                                <th>رقم الوثيقة</th>
                                <th>جهة الاصدار</th>
                                <th>طفل / بالغ</th>
                            </tr>
                            <tr>
                                <td>{{ item.fullName }}</td>
                                <td>{{ item.documentTypeDocumentName }}</td>
                                <td>{{ item.documentNo }}</td>
                                <td>{{ item.issuer }}</td>
                                <td>{{ item.isChiled ? "بالغ" : "طفل" }}</td>
                            </tr>
                        </table>

                        <!-- <div class="d-flex justify-space-between">
                            <span class="grey--text ">الاسم الكامل</span>
                            <span class="grey--text ">نوع الوثيقة</span>
                            <span class="grey--text ">رقم الوثيقة</span>
                            <span class="grey--text ">جةة الاصدار</span>
                            <span class="grey--text ">طفل / بالغ</span>
                        </div>

                        <div class="d-flex justify-space-between mt-2">
                            <span>{{ item.fullName }}</span>
                            <span>{{ item.documentTypeDocumentName }}</span>
                            <span>{{ item.documentNo }}</span>
                            <span>{{ item.issuer }}</span>
                            <span>{{ item.isChiled ? "بالغ" : "طفل" }}</span>
                        </div>-->
                    </div>
                </v-alert>
            </v-col>

            <v-col cols="12" md="6" class="pl-2 text-center">
                <v-alert outlined color="blue" border="left">
                    <p class="mb-3">تفاصيل الغرف المحجوزة</p>
                    <div
                        v-for="(room, index) in detail.reservedRooms"
                        :key="index"
                    >
                        <table class="" style="width:100%">
                            <tr class="grey--text">
                                <th>رقم الغرفة</th>
                                <th>نوع الغرفة</th>
                                <th>سعر الغرفة</th>
                                <th>عدد الاسرة</th>
                            </tr>
                            <tr>
                                <td>{{ room.roomRoomNumber }}</td>
                                <td>{{ room.roomRoomTypeRoomTypeName }}</td>
                                <td>{{ room.roomTotalPrice }}</td>
                                <td>{{ room.roomBedCount }}</td>
                            </tr>
                        </table>

                        <!-- <div class="d-flex justify-space-between">
                            <span class="grey--text "> رقم الغرفة</span>
                            <span class="grey--text ">نوع الغرفة</span>
                            <span class="grey--text ">سعر الغرفة</span>
                            <span class="grey--text ">عدد الاسرة</span>
                        </div>

                        <div class="d-flex justify-space-between mt-2">
                            <span>{{ room.roomRoomNumber }}</span>
                            <span>{{ room.roomRoomTypeRoomTypeName }}</span>
                            <span>{{ room.roomTotalPrice }}</span>
                            <span>{{ room.roomBedCount }}</span>
                        </div> -->
                    </div>
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detail: {}
        };
    },

    props: ["id"],

    created() {
        this.getItem();
    },

    methods: {
        getItem() {
            this.loading = true;
            this.$GenericService
                .getAll("reservation/" + this.id)
                .then(res => {
                    this.detail = res.data.data;
                    this.loading = false;
                    console.log(res.data.data);
                })
                .catch(err => {});
        }
    }
};
</script>

<style></style>
